@import "../mixins/flex.scss";
@import "../mixins/fonts.scss";
@import "../utilities/variables.scss";

.bannerWrap {
  background: var(--bg-primary);
  position: relative;
  .bannerContainer {
    padding: 46px 32px 36px 32px;
    .content {
      text-align: center;
      .headerWrap {
        margin-bottom: 28px;
        .header {
          @include setFontPrimaryBold($fs-h3, var(--clr-primary));
        }
      }

      .queryWrap {
        background-color: var(--bg-white);
        border: 1px solid var(--border-primary);
        border-radius: 8px;
        margin: 0 auto;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
          rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
        .list {
          @include flexCenterNil();
          .listItem {
            @include flexStart();
            width: 50%;
            padding: 12px;
            cursor: pointer;
            p {
              @include setFontPrimary($fs-p3, var(--clr-secondary-ltr));
            }
          }
          .listItemSelected {
            background: var(--clr-secondary);
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            p {
              @include setFontPrimarySemiBold($fs-p3, var(--text-white));
            }
          }
          .borderRT {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
          }
          .borderLT {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
          }
        }

        .queryForm {
          padding: 24px;
          .queryInput {
            display: flex;
            flex-direction: column;
            gap: 12px;
            .formWrap {
              display: flex;
              flex-direction: column;
              gap: 12px;
              .err {
                @include setFontPrimary(18px, var(--clr-danger));
              }
              .title {
                width: 100%;
                input {
                  padding: 20px;
                  margin: 0px;
                }
              }
            }
          }
        }
      }
    }
  }
  .lineLf {
    position: absolute;
    left: 0;
    background-image: url(../../public/images/banner/line-lf.png);
    top: 0;
    width: 100px;
    height: 100px;
    background-position: top;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .lineRt {
    position: absolute;
    right: 0px;
    background-image: url(../../public/images/banner/line-rt.png);
    bottom: 0;
    width: 100px;
    height: 100%;
    background-position: bottom right;
    background-size: 80%;
    background-repeat: no-repeat;
  }
}

@media screen and (min-width: 768px) {
  .bannerWrap {
    background: url("../../public/images/banner/banner.png"), var(--bg-primary);
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    .bannerContainer {
      .content {
        text-align: left;
        .headerWrap {
          max-width: 60%;
          margin-bottom: 58px;
        }

        .queryWrap {
          margin: 0;
          .list {
            .listItem {
              padding: 12px 24px;
            }
          }
          .queryForm {
            .queryInput {
              flex-direction: row;
              .formWrap {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                width: 80%;
                gap: 0;
                justify-content: space-between;
                .type {
                  width: 48%;
                }
                .google {
                  width: 48%;
                }
                .detect {
                  width: 100%;
                  display: flex;
                  justify-content: flex-end;
                  margin-top: 10px;
                }
                .err {
                  @include setFontPrimary(18px, var(--clr-danger));
                  padding-top: 10px;
                  text-align: right;
                  width: 100%;
                }
              }
              .formAction {
                width: 20%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 991px) {
  .bannerWrap {
    .bannerContainer {
      padding: 90px 36px;
      .content {
        .headerWrap {
          margin-bottom: 78px;
          .header {
            @include setFontSize($fs-h1);
          }
        }
        .queryWrap {
          max-width: 60%;
        }
      }
    }
  }
}
