@import "../mixins/fonts.scss";

.header {
  text-align: center;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--border-dk);
  .title {
    @include setFontPrimarySemiBold(16px, var(--clr-secondary));
  }
}

.content {
  padding: 24px 16px;
  h3{
    @include setFontPrimarySemiBold(16px, var(--clr-secondary));
  }
}

.actions {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid var(--border-dk);
}