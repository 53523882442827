@mixin flexWrap {
  display: flex;
  flex-wrap: wrap;
}

@mixin flexCenterNil {
  display: flex;
  align-items: center;
}

@mixin flexTopNil {
  display: flex;
  align-items: flex-start;
}

@mixin flexWrapAlign {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@mixin flexCenterBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flexTopBetween {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@mixin flexStart {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

@mixin flexJustifyEnd {
  display: flex;
  justify-content: flex-end;
}

@mixin flexNilCenterGapCustom($gap-number: 10px) {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: $gap-number;
}

@mixin verticalCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin flexCenterCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin alignCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin formFlex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@mixin formFlexItem {
  width: 48%;
}

@mixin alignRight {
  display: flex;
  justify-content: flex-end;
}
